.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Color */
.bg-primary {
  background-color: #0F172A;
}

.bg-secondary {
  background-color: #1E293B;
}

.bg-accent {
  background-color: #74BB77;
}

.text-accent {
  color: #74BB77;
}

.mobile-nav {
  @apply block md:hidden fixed top-12 py-2 w-full h-48 duration-500 bg-stone-800 bg-opacity-80;
}

nav li a {
  @apply px-4 py-5 text-2xl;
}

nav li a:hover {
    color: #74BB77;
    text-decoration: none;
}

body {
  background-image: url("assets/portfolioBackground.webp");
  background-repeat: repeat;
}

a {
  @apply text-2xl;
}

p {
  @apply text-2xl font-light;
}




/* .hero-img {
  -webkit-box-shadow: inset 5px 5px 150px 5px #00001A; 
  box-shadow: inset 5px 5px 20px 5px #00001A;
  border-radius: 15% 15% 15% 15%;
} */

/* .hero-img:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: none;
} */